<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
      <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>自动重发</el-breadcrumb-item>
  <el-breadcrumb-item>重发设置</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">

    <!-- 搜索区域 -->
    <el-form :inline="true" class="demo-form-inline">
      <div class="demo-input-suffix">
        <el-form-item label="请选择">
          <el-select v-model="queryInfo.userCategorysID" placeholder="请选择" @change="userCategorysClick">
            <el-option
              key="0"
              label="全部"
              value="">
            </el-option>
            <el-option
              v-for="group in userCategorys"
              :key="group.id"
              :label="group.name"
              :value="group.id">
              <el-option
                v-for="item in group.subCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-option>
          </el-select>
          </el-form-item>
          <el-button type="danger" @click="handleSetup">设置计划任务</el-button>
          <el-button type="danger" @click="onekeyChongfa" v-loading.fullscreen.lock="fullscreenLoading">一键设置自动重发</el-button>
          <!-- <span class="fm">
          <el-form-item label="请选择">
            <el-select v-model="userCategorys" placeholder="活动区域">
              <el-option label="10" value="shanghai"></el-option>
              <el-option label="20" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          </span> -->
      </div>
    </el-form>

    <!-- 商品列表区域 -->
    <div class="main" v-loading="loading">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选本页</el-checkbox>
      <!-- 数据为空时 -->
      <el-empty description="数据不存在" v-if="dataShow"></el-empty>

      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" v-else>
        <el-row :gutter="10">
            <el-col :span="6" v-for="item in tableData" :key="item.productID">
              <el-card :body-style="{ padding: '10px' }">
                <el-image
                style="height: 220px"
                :src="item.image" fit="cover">
                </el-image>
                <div style="padding: 14px;">
                  <span class="text-overflow1">{{item.subject}}</span>
                  <div class="bottom">
                    <el-checkbox :label="item" :key="item">选择</el-checkbox>
                  </div>
                </div>
              </el-card>
            </el-col>
        </el-row>
      </el-checkbox-group>
    </div>

    <!-- 分页区域 -->
    <div class="mt20" v-if="dataShow === false">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20]"
        :page-size="queryInfo.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

</el-card>

<!-- 设置操作 -->
<el-form :model="ruleTask" ref="ruleTaskForm" label-width="100px">
  <el-dialog title="重发计划任务" v-model="dialogFormVisible" width="680px">

      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="任务列表">

            <!-- 商品列表区域 -->
            <el-table :data="taskData" stripe style="width: 100%">
              <!-- <el-table-column type="selection" width="55"></el-table-column> -->
              <el-table-column type="index"></el-table-column>
              <el-table-column label="任务名称" prop="title"></el-table-column>
              <el-table-column label="任务时间" prop="task_time"></el-table-column>
              <el-table-column label="数量" prop="num"></el-table-column>
              <!-- <el-table-column label="最后重发时间" prop="update_time"></el-table-column> -->
              <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="mini" type="primary" @click="handleInsert(scope.$index, scope.row)">加入</el-button>
                    <!-- <el-button size="mini" type="primary" @click="handleInsert(scope.$index, scope.row)">编辑</el-button> -->
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <div class="mt20">
              <el-pagination
                @size-change="taskSizeChange"
                @current-change="taskCurrentChange"
                :current-page="taskqueryInfo.page"
                :page-sizes="[5, 10, 20]"
                :page-size="taskqueryInfo.page_size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tasktotal">
              </el-pagination>
            </div>
        </el-tab-pane>
        <el-tab-pane label="新增任务">

          <el-form-item label="活动名称" :label-width="formLabelWidth">
            <el-input v-model="title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="重发时间" :label-width="formLabelWidth">
            <el-time-picker
              v-model="time"
              placeholder="任意时间点"
              value-format='HH:mm:ss'>
            </el-time-picker>
          </el-form-item>
          <el-form-item label="选中产品" :label-width="formLabelWidth">
            {{countNum}}
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <span class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitForm()">确 定</el-button>
            </span>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>

  </el-dialog>

</el-form>
      <!-- 内容 结束 -->
    </el-main>
    </el-container>
</el-container>

</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getLists } from 'network/productList'
import { listuserCategory } from 'network/usercategory'
import { taskDataSave, taskLists, taskDataAll } from 'network/task'
import { tasklistDataSave, taskDeleteByid } from 'network/tasklist'
import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'

// import { getLists, deleteByid, deleteAllByid } from 'network/data'
const dayjs = require('dayjs')

export default defineComponent({
  name: 'Chongfa',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    const queryInfo = reactive({
      // 搜索关键字
      query: '',
      // 当前状态
      userCategorys: 'shanghai',
      // 自定定分类
      userCategorysID: '',
      // 当前的页数
      page: 1,
      // 当前每页显示多少条
      page_size: 20
    })

    const taskqueryInfo = reactive({
      // 当前的页数
      page: 1,
      // 当前每页显示多少条
      page_size: 5
    })

    const options = reactive({
      tableData: [],
      taskData: []
    })

    const setup = reactive({
      dialogTableVisible: false,
      dialogFormVisible: false,
      ruleTask: {
        title: ''
      },
      formLabelWidth: '120px'
    })

    // 全选
    const state = reactive({
      dataShow: false,
      checkAll: false,
      checkedCities: [],
      cities: options.tableData,
      isIndeterminate: true,
      ruleTask: '',
      // 总页数
      total: 0,
      userCategorys: [],
      loading: true,
      tabPosition: 'top',
      // 总页数
      tasktotal: 0
    })

    const TaskForm = reactive({
      row: [],
      title: '计划任务' + dayjs().format('YYYY-MM-DD'),
      time: dayjs().format('HH:mm:ss'),
      countNum: 0,
      goodslist: []
    })

    const fullscreenLoading = ref(false)

    const handleCheckAllChange = (val) => {
      TaskForm.goodslist = options.tableData
      TaskForm.countNum = options.tableData.length
      state.checkedCities = val ? options.tableData : []
      state.isIndeterminate = false
    }

    const handleCheckedCitiesChange = (value) => {
      // console.log(value)
      TaskForm.goodslist = value
      const checkedCount = value.length
      state.checkAll = checkedCount === state.cities.length
      state.isIndeterminate = checkedCount > 0 && checkedCount < state.cities.length
      TaskForm.countNum = value.length
    }

    // 一键重发
    const onekeyChongfa = () => {
      ElMessageBox.confirm('此操作将自动设置重发产品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 调用数量
          fullscreenLoading.value = true
          taskDataAll().then(res => {
            if (res.status === 1) {
              taskdataInfo(taskqueryInfo)
              ElMessage.success(res.message)
            } else {
              ElMessage.error(res.message)
            }
            fullscreenLoading.value = false
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消'
          })
        })
    }

    // 新增
    const handleInsert = (index, row) => {
      // console.log(index.id)
      // console.log(row)
      // console.log(TaskForm.goodslist)
      if (TaskForm.countNum <= 0) {
        ElMessage.error('选择产品不可以为空！')
        return false
      }
      TaskForm.row = row
      // console.log(TaskForm)
      tasklistDataSave(TaskForm).then(res => {
        if (res.status === 1) {
          taskdataInfo(taskqueryInfo)
          ElMessage.success(res.message)
        } else {
          // options.tableData = []
          ElMessage.error('res.message')
        }
      })
    }

    // 删除
    const handleDelete = (index, row) => {
      console.log(row.id)
      taskDeleteByid(row).then(res => {
        if (res.status === 1) {
          taskdataInfo(taskqueryInfo)
          ElMessage.success(res.message)
        } else {
          // options.tableData = []
          ElMessage.error(res.message)
        }
      })
    }

    // 设置
    const handleSetup = () => {
      // console.log(options.tableData.length)
      taskdataInfo(taskqueryInfo)
      setup.dialogFormVisible = true
    }

    // 验证任务表单
    // const ruleTaskForm = ref(null)
    // // 表单规则
    // const ruleTaskRules = {
    //   titleRules: [
    //     { required: true, message: '请输入任务名称', trigger: 'blur' }
    //   ]
    // }
    // 确认任务表单
    const submitForm = () => {
      if (TaskForm.title === '') {
        ElMessage.error('活动名称不可以为空！')
        return false
      }
      if (TaskForm.countNum <= 0) {
        ElMessage.error('选择产品不可以为空！')
        return false
      }
      // console.log(TaskForm)
      taskDataSave(TaskForm).then(res => {
        if (res.status === 1) {
          taskdataInfo(taskqueryInfo)
          ElMessage.success(res.message)
        } else {
          // options.tableData = []
          ElMessage.error(res.message)
        }
      })
    }

    // 卖家查询商品列表
    const dataInfo = (queryInfo) => {
      // 复制记录
      state.loading = true
      getLists(queryInfo).then(res => {
        // console.log(res)
        if (res.status === 1) {
          if (res.result.list.length <= 0) {
            state.dataShow = true
          } else {
            state.dataShow = false
            options.tableData = res.result.list
            queryInfo.page = res.result.pageIndex
            queryInfo.page_size = res.result.sizePerPage
            state.total = res.result.totalRecords
          }
          state.loading = false
        } else {
          options.tableData = []
        }
      })
    }

    // 分页
    const handleSizeChange = (val) => {
      // console.log(`每页 ${val} 条`)
      queryInfo.page_size = val
      dataInfo(queryInfo)
    }
    const handleCurrentChange = (val) => {
      // console.log(`当前页: ${val}`)
      queryInfo.page = val
      dataInfo(queryInfo)
    }

    // 定时任务列表
    const taskdataInfo = (taskqueryInfo) => {
      // 复制记录
      // state.loading = true
      taskLists(taskqueryInfo).then(res => {
        console.log(res.result.data)
        if (res.status === 1) {
          options.taskData = res.result.data
          taskqueryInfo.page = res.result.current_page
          taskqueryInfo.page_size = res.result.per_page
          state.tasktotal = res.result.total
        } else {
          options.taskData = []
        }
      })
    }
    // 分页
    const taskSizeChange = (val) => {
      // console.log(`每页 ${val} 条`)
      taskqueryInfo.page_size = val
      // dataInfo(queryInfo)
      taskdataInfo(taskqueryInfo)
    }
    const taskCurrentChange = (val) => {
      // console.log(`当前页: ${val}`)
      taskqueryInfo.page = val
      // dataInfo(queryInfo)
      taskdataInfo(taskqueryInfo)
    }
    // 点击自定义分类跳转
    const userCategorysClick = (val) => {
      console.log(`当前所属分类: ${val}`)
      queryInfo.userCategorysID = val
      dataInfo(queryInfo)
    }

    onMounted(() => {
      // 获取授权用户的商品自定义分类列表
      listuserCategory().then(res => {
        if (res.status === 1) {
          state.userCategorys = res.result
          // console.log(res.result)
        } else {
          state.userCategorys = []
        }
      })
      dataInfo(queryInfo)
    })
    return {
      queryInfo,
      taskqueryInfo,
      ...toRefs(state),
      ...toRefs(options),
      ...toRefs(setup),
      ...toRefs(TaskForm),
      dataInfo,
      handleCheckAllChange,
      handleCheckedCitiesChange,
      onekeyChongfa,
      handleSetup,
      submitForm,
      handleSizeChange,
      handleCurrentChange,
      userCategorysClick,
      taskSizeChange,
      taskCurrentChange,
      handleInsert,
      handleDelete,
      fullscreenLoading,
      navActive: '3-1'
    }
  }
})
</script>
<style lang="scss" scoped>
.main{
  margin-top: 20px;
}
.el-select .el-input {
    width: 130px;
  }
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
.el-col{
  margin-bottom: 20px;
  font-size: 14px;
}
.el-checkbox{
  margin-bottom: 15px;
}
.fm{
  float:right;
}
</style>
