import { request } from './request'

/*
* 添加复制商品
*/
export function taskDataSave (data) {
  return request({
    url: '/api/alibaba/task/save',
    method: 'post',
    data
  })
}

/**
 * 复制列表
 */
export function taskLists (params) {
  return request({
    url: '/api/alibaba/task/getLists',
    method: 'get',
    params
  })
}

/**
 * 复制列表
 */
export function getTaskByid (params) {
  return request({
    url: '/api/alibaba/task/getTaskByid',
    method: 'get',
    params
  })
}

/**
 * 复制列表
 */
export function getTaskAll (params) {
  return request({
    url: '/api/alibaba/task/getTaskAll',
    method: 'get',
    params
  })
}

/**
 * 一键设置自动重发
 */
export function taskDataAll (params) {
  return request({
    url: '/api/alibaba/task/saveAll',
    method: 'get',
    params
  })
}

/**
 * 复制列表
 */
export function editData (data) {
  return request({
    url: '/api/alibaba/task/editdata',
    method: 'post',
    data
  })
}

/**
 * 删除任务
 */
// export function deleteByid (data) {
//   return request({
//     url: '/api/alibaba/task/deleteByid',
//     method: 'post',
//     data
//   })
// }
