import { request } from './request'

/*
* 卖家查询商品列表
*/
export function getLists (params) {
  return request({
    url: '/api/alibaba.open.productList',
    method: 'get',
    params
  })
}
