import { request } from './request'

/*
* 添加任务商品
*/
export function tasklistDataSave (data) {
  return request({
    url: '/api/alibaba/tasklist/save',
    method: 'post',
    data
  })
}

/**
 * 任务列表
 */
export function tasklist (params) {
  return request({
    url: '/api/alibaba/tasklist/getLists',
    method: 'get',
    params
  })
}

/**
 * 任务列表
 */
export function taskDeleteByid (data) {
  return request({
    url: '/api/alibaba/task/deleteByid',
    method: 'post',
    data
  })
}

/**
 * 任务列表
 */
export function getUserLists (params) {
  return request({
    url: '/api/alibaba/tasklist/getUserLists',
    method: 'get',
    params
  })
}

/**
 * 根据ID复制商品
 */
export function deleteAllByid (data) {
  return request({
    url: '/api/alibaba/tasklist/deleteAllByid',
    method: 'post',
    data
  })
}
